<template>
  <div
    style="height: inherit"
  >
    <b-card
      no-body
      class="p-2"
    >
      <b-row class="align-items-center justify-content-between">
        <b-col
          sm="12"
          md="6"
        >
          <h5 class="m-0">
            Bracket
          </h5>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="tournament?.status_id !== TournamentStatusType.IN_PROGRESS && tournament?.status_id !== TournamentStatusType.FINISHED && tournament?.status_id !== TournamentStatusType.CLOSED "
      no-body
      class="p-2"
    >
      <b-row>
        <b-col
          cols="5"
          class="d-flex flex-column align-items-center"
        >
          Available when tournament is started
        </b-col>
      </b-row>

    </b-card>

    <div
      v-else
      style="height: calc(100% - 90px) !important;"
    >
      <b-row
        v-if="
          isLoading"
        class="text-center p-2"
      >
        <b-spinner label="Loading..." />
      </b-row>

      <vue-perfect-scrollbar
        v-else
        :settings="perfectScrollbarSettings"
        class="ps-customizer-area scroll-area h-100"
      >
        <b-row class="mb-2">
          <b-col>
            <bracket
              :key="1"
              :add-grand-finale-round="true"
              :flat-tree="winnerNodes"
              :is-loading-match="isLoadingMatch"
              :loading-match-id="loadingMatchId"
              @onMatchInfoModalShow="handleMatchInfoModalShow"
            >
              <template #player="{ player }">
                {{ player.name }}
              </template>
            </bracket>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col>
            <bracket
              :key="2"
              :flat-tree="looserNodes"
              :show-edit-results="canEditResults"
              :is-loading-match="isLoadingMatch"
              :loading-match-id="loadingMatchId"
              @onMatchInfoModalShow="handleMatchInfoModalShow"
            >
              <template #player="{ player }">
                {{ player.name }}
              </template>
            </bracket>
          </b-col>
        </b-row>

      </vue-perfect-scrollbar>

    </div>
    <match-info-modal :match="selectedMatch" />
    <match-result-modal :match="selectedMatch" />
  </div>
</template>
<script>
import {
  BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'
import TournamentStatusType from '@/constants/TournamentStatusType'
import Bracket from '@/components/bracket/Bracket.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import MatchInfoModal from '@/views/tournament/MatchInfoModal.vue'
import MatchResultModal from '@/views/tournament/MatchResultModal.vue'
import { MemberRoleType } from '@/constants/MemberRoleType'
import MatchStatusType from '@/constants/MatchStatusType'

export default {
  components: {
    MatchResultModal,
    MatchInfoModal,
    Bracket,
    BSpinner,
    BCard,
    BRow,
    BCol,
    VuePerfectScrollbar,
  },
  mixins: [breadcrumbManager],
  data() {
    return {
      participants: [],
      searchQuery: '',
      selectedMatch: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 200,
      },
      showShowResultsButton: true,
      bracket: null,
      isLoading: false,
      isLoadingMatch: false,
      loadingMatchId: null,
    }
  },
  computed: {
    TournamentStatusType() {
      return TournamentStatusType
    },
    me() {
      return this.$store.getters['user/getMe']
    },
    tournament() {
      return this.$store.getters['tournament/getTournament']
    },
    winnerNodes() {
      return this.bracket?.filter(node => node.group.index === 1)
        ?.map(node => ({
          id: node.id,
          next: node.next_match_id,
          matchId: node.match_id,
          roundId: node.round_id,
          round: node.round,
          player1: {
            id: node.opponent1?.participant?.id,
            name: node.opponent1?.participant?.name || null,
            score: node.result?.opponent1_score !== null ? node.result?.opponent1_score : null,
            result: node.result?.opponent1_result || null,
            myParticipant: this.isMyParticipant(node.opponent1?.participant),
          },
          player2: {
            id: node.opponent2?.participant?.id,
            name: node.opponent2?.participant?.name || null,
            score: node.result?.opponent2_score !== null ? node.result?.opponent2_score : null,
            result: node.result?.opponent2_result || null,
            myParticipant: this.isMyParticipant(node.opponent2?.participant),
          },
        }))
    },
    looserNodes() {
      return this.bracket?.filter(node => node.group.index === -1)
        ?.map((node, index, array) => ({
          id: node.id,
          next: index === array.length - 1 ? null : node.next_match_id,
          matchId: node.match_id,
          roundId: node.round_id,
          round: node.round,
          player1: {
            id: node.opponent1?.participant?.id || null,
            name: node.opponent1?.participant?.name || null,
            score: node.result?.opponent1_score !== null ? node.result?.opponent1_score : null,
            result: node.result?.opponent1_result || null,
            myParticipant: this.isMyParticipant(node.opponent1?.participant),
          },
          player2: {
            id: node.opponent2?.participant?.id || null,
            name: node.opponent2?.participant?.name || null,
            score: node.result?.opponent2_score !== null ? node.result?.opponent2_score : null,
            result: node.result?.opponent2_result || null,
            myParticipant: this.isMyParticipant(node.opponent2?.participant),
          },
        }))
    },
  },
  async created() {
    await this.$store.dispatch('tournament/fetchTournament', this.$route.params.id)

    await this.hydrateBreadcrumbs()

    await this.fetchBrackets()
  },
  methods: {
    isMyParticipant(participant) {
      return !!this.me?.teams?.find(team => team.id === participant?.id)
    },
    // eslint-disable-next-line vue/no-dupe-keys
    canEditResults(match) {
      const myTeam = this.me?.teams?.find(
        team => (team.id === match?.opponent1?.participant?.id || team.id === match?.opponent2?.participant?.id)
            && team.members.some(member => member.user.id === this.me.id && (member.role.id === MemberRoleType.TEAM_LEADER || member.role.id === MemberRoleType.OWNER)),
      )

      const isMeParticipating = match?.opponent1?.participant?.id === this.me?.id
          || match?.opponent2?.participant?.id === this.me?.id

      return myTeam || isMeParticipating
    },
    async handleMatchInfoModalShow(matchId) {
      this.selectedMatch = await this.fetchMatch(matchId)

      if (this.canEditResults(this.selectedMatch) && this.selectedMatch?.status_id === MatchStatusType.READY) {
        this.$bvModal.show('match-result-modal')
      } else {
        // view result modal
        this.$bvModal.show('match-info-modal')
      }
    },
    async fetchMatch(matchId) {
      this.isLoadingMatch = true
      this.loadingMatchId = matchId
      const { data } = await this.$api.tournament.matchById(matchId)

      this.isLoadingMatch = false
      return data
    },
    async hydrateBreadcrumbs() {
      if (this.tournament != null) {
        this.replaceBreadcrumb({
          find: 'tournamentName',
          replace: {
            text: this.tournament?.name,
            loading: false,
          },
        })
      }
    },
    async fetchBrackets() {
      this.isLoading = true
      const { data } = await this.$api.tournament.fetchBracket(this.$route.params.id)
      this.isLoading = false
      this.bracket = data
    },
    handleRefreshBracket() {
      this.fetchBrackets()
    },
  },
}
</script>

var render = function () {
  var _vm$match, _vm$match$opponent, _vm$match$opponent$pa, _vm$match2, _vm$match2$opponent, _vm$match3, _vm$match3$opponent, _vm$match3$opponent$p, _vm$match4, _vm$match4$opponent, _vm$match5, _vm$match5$opponent, _vm$match6, _vm$match6$opponent, _vm$match6$opponent$p, _vm$match7, _vm$match7$opponent, _vm$match8, _vm$match8$opponent, _vm$match8$opponent$p, _vm$match9, _vm$match9$opponent, _vm$match10, _vm$match10$opponent, _vm$match11, _vm$match11$result, _vm$match12, _vm$match12$result, _vm$match13, _vm$match13$result, _vm$match14, _vm$match14$result, _vm$match15, _vm$match15$result, _vm$match16, _vm$match16$result, _vm$match17, _vm$match17$result, _vm$match18, _vm$match18$result, _vm$match19, _vm$match19$result, _vm$match20, _vm$match20$result;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "match-info-modal",
      "title": _vm.title,
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])];
      }
    }])
  }, [_c('b-container', {
    staticClass: "p-1"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$match = _vm.match) === null || _vm$match === void 0 ? void 0 : (_vm$match$opponent = _vm$match.opponent1) === null || _vm$match$opponent === void 0 ? void 0 : (_vm$match$opponent$pa = _vm$match$opponent.participant) === null || _vm$match$opponent$pa === void 0 ? void 0 : _vm$match$opponent$pa.avatar_image,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('h3', [_vm._v(" " + _vm._s((_vm$match2 = _vm.match) !== null && _vm$match2 !== void 0 && (_vm$match2$opponent = _vm$match2.opponent1) !== null && _vm$match2$opponent !== void 0 && _vm$match2$opponent.participant ? (_vm$match3 = _vm.match) === null || _vm$match3 === void 0 ? void 0 : (_vm$match3$opponent = _vm$match3.opponent1) === null || _vm$match3$opponent === void 0 ? void 0 : (_vm$match3$opponent$p = _vm$match3$opponent.participant) === null || _vm$match3$opponent$p === void 0 ? void 0 : _vm$match3$opponent$p.name : ((_vm$match4 = _vm.match) === null || _vm$match4 === void 0 ? void 0 : (_vm$match4$opponent = _vm$match4.opponent1) === null || _vm$match4$opponent === void 0 ? void 0 : _vm$match4$opponent.name) || 'TBD') + " ")]), _c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, _vm._l((_vm$match5 = _vm.match) === null || _vm$match5 === void 0 ? void 0 : (_vm$match5$opponent = _vm$match5.opponent1) === null || _vm$match5$opponent === void 0 ? void 0 : _vm$match5$opponent.lineup, function (participant, index) {
    var _participant$player;
    return _c('b-badge', {
      key: index,
      staticClass: "m-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s((participant === null || participant === void 0 ? void 0 : (_participant$player = participant.player) === null || _participant$player === void 0 ? void 0 : _participant$player.name) || (participant === null || participant === void 0 ? void 0 : participant.name)) + " ")]);
  }), 1)], 1)], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('h3', [_vm._v("VS")])])]), _c('b-col', {
    staticClass: "d-flex flex-column align-items-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$match6 = _vm.match) === null || _vm$match6 === void 0 ? void 0 : (_vm$match6$opponent = _vm$match6.opponent2) === null || _vm$match6$opponent === void 0 ? void 0 : (_vm$match6$opponent$p = _vm$match6$opponent.participant) === null || _vm$match6$opponent$p === void 0 ? void 0 : _vm$match6$opponent$p.avatar_image,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('h3', [_vm._v(" " + _vm._s((_vm$match7 = _vm.match) !== null && _vm$match7 !== void 0 && (_vm$match7$opponent = _vm$match7.opponent2) !== null && _vm$match7$opponent !== void 0 && _vm$match7$opponent.participant ? (_vm$match8 = _vm.match) === null || _vm$match8 === void 0 ? void 0 : (_vm$match8$opponent = _vm$match8.opponent2) === null || _vm$match8$opponent === void 0 ? void 0 : (_vm$match8$opponent$p = _vm$match8$opponent.participant) === null || _vm$match8$opponent$p === void 0 ? void 0 : _vm$match8$opponent$p.name : ((_vm$match9 = _vm.match) === null || _vm$match9 === void 0 ? void 0 : (_vm$match9$opponent = _vm$match9.opponent2) === null || _vm$match9$opponent === void 0 ? void 0 : _vm$match9$opponent.name) || 'TBD') + " ")]), _c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, _vm._l((_vm$match10 = _vm.match) === null || _vm$match10 === void 0 ? void 0 : (_vm$match10$opponent = _vm$match10.opponent2) === null || _vm$match10$opponent === void 0 ? void 0 : _vm$match10$opponent.lineup, function (participant, index) {
    var _participant$player2;
    return _c('b-badge', {
      key: index,
      staticClass: "m-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s((participant === null || participant === void 0 ? void 0 : (_participant$player2 = participant.player) === null || _participant$player2 === void 0 ? void 0 : _participant$player2.name) || (participant === null || participant === void 0 ? void 0 : participant.name)) + " ")]);
  }), 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('h4', [_vm._v(_vm._s(_vm.matchStatusInfo))])])], 1), (_vm$match11 = _vm.match) !== null && _vm$match11 !== void 0 && (_vm$match11$result = _vm$match11.result) !== null && _vm$match11$result !== void 0 && _vm$match11$result.opponent1_result && (_vm$match12 = _vm.match) !== null && _vm$match12 !== void 0 && (_vm$match12$result = _vm$match12.result) !== null && _vm$match12$result !== void 0 && _vm$match12$result.opponent2_result ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center mt-1",
    attrs: {
      "cols": "5"
    }
  }, [((_vm$match13 = _vm.match) === null || _vm$match13 === void 0 ? void 0 : (_vm$match13$result = _vm$match13.result) === null || _vm$match13$result === void 0 ? void 0 : _vm$match13$result.opponent1_score) !== null ? _c('div', [_c('h2', [_vm._v(_vm._s((_vm$match14 = _vm.match) === null || _vm$match14 === void 0 ? void 0 : (_vm$match14$result = _vm$match14.result) === null || _vm$match14$result === void 0 ? void 0 : _vm$match14$result.opponent1_score))])]) : _vm._e(), _c('div', {
    staticClass: "mt-auto"
  }, [_c('b-badge', {
    staticClass: "p-sm-1 font-medium-2",
    attrs: {
      "variant": _vm.matchResultBadgeColor((_vm$match15 = _vm.match) === null || _vm$match15 === void 0 ? void 0 : (_vm$match15$result = _vm$match15.result) === null || _vm$match15$result === void 0 ? void 0 : _vm$match15$result.opponent1_result)
    }
  }, [_vm._v(" " + _vm._s(_vm.matchResultText((_vm$match16 = _vm.match) === null || _vm$match16 === void 0 ? void 0 : (_vm$match16$result = _vm$match16.result) === null || _vm$match16$result === void 0 ? void 0 : _vm$match16$result.opponent1_result)) + " ")])], 1)]), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('b-col', {
    staticClass: "d-flex flex-column align-items-center mt-1",
    attrs: {
      "cols": "5"
    }
  }, [((_vm$match17 = _vm.match) === null || _vm$match17 === void 0 ? void 0 : (_vm$match17$result = _vm$match17.result) === null || _vm$match17$result === void 0 ? void 0 : _vm$match17$result.opponent2_score) !== null ? _c('div', [_c('h2', [_vm._v(_vm._s((_vm$match18 = _vm.match) === null || _vm$match18 === void 0 ? void 0 : (_vm$match18$result = _vm$match18.result) === null || _vm$match18$result === void 0 ? void 0 : _vm$match18$result.opponent2_score))])]) : _vm._e(), _c('div', {
    staticClass: "mt-auto"
  }, [_c('b-badge', {
    staticClass: "p-sm-1 font-medium-2",
    attrs: {
      "variant": _vm.matchResultBadgeColor((_vm$match19 = _vm.match) === null || _vm$match19 === void 0 ? void 0 : (_vm$match19$result = _vm$match19.result) === null || _vm$match19$result === void 0 ? void 0 : _vm$match19$result.opponent2_result)
    }
  }, [_vm._v(" " + _vm._s(_vm.matchResultText((_vm$match20 = _vm.match) === null || _vm$match20 === void 0 ? void 0 : (_vm$match20$result = _vm$match20.result) === null || _vm$match20$result === void 0 ? void 0 : _vm$match20$result.opponent2_result)) + " ")])], 1)])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
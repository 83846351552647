<template>
  <div
    v-if="recursiveBracket"
    class="vtb-wrapper flex-column"
  >
    <b-row class="m-0 flex-nowrap">
      <b-col
        v-for="round in roundsData"
        :key="round.id"
        class="round-container"
      >
        <p
          class="mt-1 ml-1 d-flex justify-content-around"
        >
          <span>{{ round.name || `Round ${round.index}` }}</span>
          <span
            v-if="showRoundFormat"
            class="ml-1"
            style="font-size: 12px; color: #7367f0 "
          >[ Best of {{ round.format || '1' }} ]</span>
        </p>
      </b-col>
    </b-row>
    <b-row class="m-0">
      <bracket-node
        :bracket-node="recursiveBracket"
        :highlighted-player-id="highlightedPlayerId"
        :show-edit-results="true"
        :organizer-mode="false"
        :loading-match-id="loadingMatchId"
        :is-loading-match="isLoadingMatch"
        @onDeselectedPlayer="unhighlightPlayer"
        @onMatchResultClick="matchResultClick"
      >
        <template #player="{ player }">
          <slot
            :player="player"
            name="player"
          />
        </template>
        <template #player-extension-bottom="{ match }">
          <slot
            :match="match"
            name="player-extension-bottom"
          />
        </template>
      </bracket-node>
    </b-row>

  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import BracketNode from '@/components/bracket/BracketNode.vue'
import { BCol, BRow } from 'bootstrap-vue'
import recursiveBracket from './recursiveBracket'

export default {
  name: 'Bracket',
  components: {
    BracketNode,
    BRow,
    BCol,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['rounds', 'flatTree', 'isLoadingMatch', 'loadingMatchId'],
  data() {
    return {
      highlightedPlayerId: null,
    }
  },
  computed: {
    me() {
      return this.$store.getters['auth/me']
    },
    roundsData() {
      const roundsLocal = this.flatTree?.map(node => ({
        id: node?.round?.id,
        index: node?.round?.index,
        format: node?.round?.format_id,
        name: node?.round?.name,
      }))

      return [...new Map(roundsLocal.map(item => [item.id, item])).values()]
    },
    showRoundFormat() {
      // check if any round has different format than other
      return this.roundsData.some(round => round.format !== null || round.format >= 1)
    },
    recursiveBracket() {
      if (this.flatTree) {
        return recursiveBracket.transformFlatTree(this.flatTree)
      }

      return recursiveBracket.transform(this.rounds)
    },
  },
  methods: {
    highlightPlayer(id) {
      this.highlightedPlayerId = id
    },
    unhighlightPlayer() {
      this.highlightedPlayerId = null
    },
    async matchResultClick(matchId) {
      this.$emit('onMatchInfoModalShow', matchId)
    },

  },
}
</script>

<style>
.vtb-wrapper {
  display: flex;
}

.round-container {
  max-width: 290px !important;
  min-width: 290px !important;
  font-weight: bolder;
  background-color: #293045;
  border: 2px solid #161d31;
}

.bracket-scroll-area {
  padding: 0 2rem;
  position: relative;
  height: 100%;
}

.vtb-item {
  display: flex;
  flex-direction: row-reverse;
}

.vtb-item p {
  padding: 20px;
  margin: 0;
  background-color: #999999;
}

.vtb-item-parent {
  position: relative;
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.vtb-item-players {
  flex-direction: column;
  margin: 0;
  color: white;
}

.vtb-item-players .vtb-player {
  margin: 2px;
  #border: 1px solid #404656;
  border-radius: 5px;
  padding: 10px;
  background-color: #283046;
  min-width: 200px;
  min-height: 42px;
}

.vtb-item-players .winner {
  background-color: #283046;
}

.vtb-item-players .defeated {
  background-color: #283046;
}

.vtb-item-players .winner.highlight {
  background-color: #283046;
}

.vtb-item-players .defeated.highlight {
  background-color: #283046;
}

.vtb-item-parent:after {
  position: absolute;
  content: '';
  width: 25px;
  height: 2px;
  left: 0;
  top: 50%;
  background-color: gray;
  transform: translateX(-100%);
}

.vtb-item-children {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vtb-item-child {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 2px;
  margin-bottom: 2px;
  position: relative;
}

.vtb-item-child:before {
  content: '';
  position: absolute;
  background-color: gray;
  right: 0;
  top: 50%;
  transform: translateX(100%);
  width: 25px;
  height: 2px;
}

.vtb-item-child:after {
  content: '';
  position: absolute;
  background-color: gray;
  right: -25px;
  height: calc(50% + 22px);
  width: 2px;
  top: 50%;
}

.vtb-item-child:last-child:after {
  transform: translateY(-100%);
}

.vtb-item-child:only-child:after {
  display: none;
}
</style>

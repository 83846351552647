<template>
  <b-modal
    id="match-info-modal"
    :title="title"
    no-close-on-backdrop
    size="lg"
  >
    <template #modal-footer="{ cancel }">
      <b-button
        variant="outline-primary"
        @click="cancel"
      >
        Close
      </b-button>
    </template>

    <b-container
      class="p-1"
    >
      <b-row>
        <b-col
          cols="5"
          class="d-flex flex-column align-items-center"
        >
          <b-avatar
            :src="match?.opponent1?.participant?.avatar_image"
            size="80"
            variant="secondary"
          />

          <h3>
            {{
              match?.opponent1?.participant
                  ? match?.opponent1?.participant?.name
                  : (match?.opponent1?.name || 'TBD')
            }}
          </h3>

          <!-- lineup -->
          <b-row class="d-flex justify-space-around">
            <b-col sm="12">
              <b-badge
                v-for="(participant, index) in match?.opponent1?.lineup"
                :key="index"
                variant="light-primary"
                class="m-1"
              >
                {{ participant?.player?.name || participant?.name }}
              </b-badge>
            </b-col>
          </b-row>
        </b-col>

        <b-col class="d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <h3>VS</h3>
          </div>
        </b-col>

        <b-col
          cols="5"
          class="d-flex flex-column align-items-center"
        >
          <b-avatar
            :src="match?.opponent2?.participant?.avatar_image"
            size="80"
            variant="secondary"
          />

          <h3>
            {{
              match?.opponent2?.participant
                  ? match?.opponent2?.participant?.name
                  : (match?.opponent2?.name || 'TBD')
            }}
          </h3>

          <!-- lineup -->
          <b-row class="d-flex justify-space-around">
            <b-col sm="12">
              <b-badge
                v-for="(participant, index) in match?.opponent2?.lineup"
                :key="index"
                variant="light-primary"
                class="m-1"
              >
                {{ participant?.player?.name || participant?.name }}
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Match status -->
      <b-row>
        <b-col class="d-flex justify-content-center mt-2">
          <h4>{{ matchStatusInfo }}</h4>
        </b-col>
      </b-row>

      <!-- Match result -->
      <b-row v-if="match?.result?.opponent1_result && match?.result?.opponent2_result">
        <!-- Team 1 -->
        <b-col
          class="d-flex flex-column align-items-center mt-1"
          cols="5"
        >
          <div v-if="match?.result?.opponent1_score !== null">
            <h2>{{ match?.result?.opponent1_score }}</h2>
          </div>

          <div class="mt-auto">
            <b-badge
              :variant="matchResultBadgeColor(match?.result?.opponent1_result)"
              class="p-sm-1 font-medium-2"
            >
              {{ matchResultText(match?.result?.opponent1_result) }}
            </b-badge>
          </div>
        </b-col>

        <b-col cols="2" />

        <!-- Team 2 -->
        <b-col
          class="d-flex flex-column align-items-center mt-1"
          cols="5"
        >
          <div v-if="match?.result?.opponent2_score !== null">
            <h2>{{ match?.result?.opponent2_score }}</h2>
          </div>
          <div class="mt-auto">
            <b-badge
              :variant="matchResultBadgeColor(match?.result?.opponent2_result)"
              class="p-sm-1 font-medium-2"
            >
              {{ matchResultText(match?.result?.opponent2_result) }}
            </b-badge>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BContainer,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MatchStatusType from '@/constants/MatchStatusType'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BContainer,
    BButton,
    BAvatar,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    match: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      MatchStatusType,
    }
  },
  computed: {
    title() {
      const opponent1Name = this.match?.opponent1?.participant
        ? this.match?.opponent1?.participant?.name
        : (this.match?.opponent1?.name || 'TBD')

      const opponent2Name = this.match?.opponent2?.participant
        ? this.match?.opponent2?.participant?.name
        : (this.match?.opponent2?.name || 'TBD')

      return `Match info between ${opponent1Name || '?'} vs ${
        opponent2Name || '?'
      } `
    },
    matchStatusInfo() {
      switch (this.match?.status_id) {
        case MatchStatusType.READY:
          return 'Match not played yet.'
        case MatchStatusType.RUNNING:
          return 'In progress.'
        case MatchStatusType.COMPLETE:
          return 'Match finished.'
        case MatchStatusType.LOCKED:
        case MatchStatusType.WAITING:
        default:
          return 'Match not ready yet.'
      }
    },
  },
  watch: {
    matchId(newValue) {
      this.fetchMatch(newValue)
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    matchResultText(result) {
      // eslint-disable-next-line default-case
      switch (result?.toLowerCase()) {
        case 'w':
          return 'WON'
        case 'l':
          return 'LOST'
        case 'd':
          return 'DRAW'
      }
    },
    // eslint-disable-next-line consistent-return
    matchResultBadgeColor(result) {
      // eslint-disable-next-line default-case
      switch (result?.toLowerCase()) {
        case 'w':
          return 'success'
        case 'l':
          return 'danger'
        case 'd':
          return 'secondary'
      }
    },
  },
}
</script>

<style lang="scss"></style>
